<template>
  <div>
    <pdf v-for="i in numPages" :key="i" :src="src" :page="i" ref="myPdfComponent"></pdf>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
export default {
  data() {
    return {
      src: '',
      numPages: 0,
      page: 1,
      currentPage: 0,
    }
  },
  components: {
    pdf,
  },
  mounted() {
    let datas = this.$route.query.file
    this.src = pdf.createLoadingTask({ url: datas })
    this.src.promise
      .then(pdf => {
        this.numPages = pdf.numPages
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {},
  methods: {},
}
</script>
<style lang="less" scoped></style>
